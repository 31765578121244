module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"five-observables","pages":[{"type":"Post","match":"/:uid","path":"/","component":"/Users/birdlaw/Dev/Projects/Five Observables site/five-observables-new RETRY3_usethisone/src/templates/post.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"five-observables","short_name":"five-observables","start_url":"/","background_color":"#222","theme_color":"#222","display":"minimal-ui","icon":"src/images/five-observables-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae1867b88591dd27b8af887166218856"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
